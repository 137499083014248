.meeting-list {
  @apply flex items-stretch gap-4 cursor-pointer;
}
.meeting-list__map {
  /* negative main padding values */
  @apply md:-ml-16 -mt-6 md:-mt-12 aspect-square md:aspect-[21/9] [&>*]:h-full [&+*]:mt-6 md:[&+*]:mt-12;
}
.meeting-list__link {
  @apply py-6 flex items-center justify-end gap-1 text-secondary;
}
.meeting-list__modal {
  @apply pr-16 pb-16;
}
.meeting-list__modal-title {
  @apply flex items-baseline gap-2;
}
.meeting-list__modal-text {
  @apply text-lg text-gray-2;
}
.meeting-list__modal-input {
  @apply text-lg text-gray-2 relative;
}
.meeting-list__modal-input input {
  @apply w-full;
}
.meeting-list__modal-input button {
  @apply z-10 absolute top-1/2 right-2 -translate-y-1/2 text-sm;
}
.meeting-list:hover h2 {
  @apply underline;
}
.meeting-list__block-map {
  @apply aspect-[2/1] md:aspect-[4/1] rounded overflow-hidden [&>*]:h-full;
}
.meeting-list__block-list {
  @apply flex flex-col md:flex-row justify-start gap-16 last:[&>*]:grow;
}
.meeting-list__block-list-aside {
  @apply flex flex-col gap-4 items-stretch;
}
.meeting-list__block-list-title {
  @apply text-gray-2 font-semibold uppercase;
}

.meeting__filter-container {
  @apply self-stretch;
}
.meeting__calendar {
  @apply w-14 flex flex-col justify-start rounded bg-background text-center;
}
.meeting__calendar:only-child .meeting__calendar-month {
  @apply rounded-t;
}
.meeting__calendar-container {
  @apply grid grid-cols-[auto_1fr] md:grid-cols-[auto_1fr_1fr] items-center gap-0 md:gap-x-5 border-4 border-background rounded md:h-[140px] overflow-hidden;
}
.meeting__calendar-container > *:nth-child(2) {
  @apply p-4 md:p-0;
}
.meeting__calendar-container > *:nth-child(2):not(:last-child) {
  @apply col-span-2 md:col-auto order-2 md:order-1;
}
.meeting__calendar-container > *:nth-child(2):last-child {
  @apply md:col-span-2 pr-4;
}
.meeting__calendar-container > *:nth-child(3) {
  @apply order-1 md:order-2 h-[135px] md:h-full;
}
.meeting__calendar-month {
  @apply bg-primary uppercase text-white text-xs rounded-tl;
}
.meeting__calendar-day {
  @apply text-black text-2xl font-bold;
}
.meeting__calendar-year {
  @apply text-black text-xs;
}
.meeting__calendar-month, .meeting__calendar-day, .meeting__calendar-year {
  @apply inline-flex items-center justify-evenly empty:[&>span]:hidden;
}
.meeting__calendar-separator {
  @apply mx-2 font-normal text-sm;
}
.meeting__calendar__lg {
  @apply w-fit justify-center [&>*]:px-2 min-w-24 h-[8.5rem];
}
.meeting__calendar__lg .meeting__calendar-month {
  @apply py-1 text-md;
}
.meeting__calendar__lg .meeting__calendar-day {
  @apply text-5xl;
}
.meeting__calendar__lg .meeting__calendar-year {
  @apply text-md pb-2;
}
.meeting__author {
  @apply flex items-center gap-4;
}
.meeting__grid {
  @apply grid grid-cols-4 gap-x-12 gap-y-4;
}
.meeting__online {
  @apply bg-background flex flex-col grid place-items-center p-8 space-y-4 text-gray-2 text-center text-lg;
}
.meeting__agenda-title {
  @apply flex gap-1 items-center;
}
.meeting__agenda-title svg {
  @apply w-6 h-6 text-tertiary fill-current;
}
.meeting__agenda-item {
  @apply mt-6 bg-background rounded p-4 text-gray-2;
}
.meeting__agenda-item__title {
  @apply text-lg inline-flex gap-1 first:[&>*]:font-bold;
}
.meeting__agenda-item__description {
  @apply mt-2 text-md text-gray-2;
}
.meeting__agenda-subitem {
  @apply ml-4 mt-4;
}
.meeting__agenda-subitem .meeting__agenda-item__title {
  @apply text-md;
}
.meeting__aside-progress {
  @apply text-sm text-gray-2 text-center space-y-1;
}
.meeting__aside-progress progress {
  @apply block w-full h-1 appearance-none rounded-full overflow-hidden bg-gray;
}
.meeting__aside-progress progress::-webkit-progress-value {
  @apply bg-success;
}
.meeting__aside-progress progress::-webkit-progress-bar {
  @apply bg-gray lg:bg-white;
}
.meeting__aside-progress progress::-moz-progress-bar {
  @apply bg-success;
}
.meeting__aside-progress-label {
  @apply text-sm font-bold lg:hidden;
}
.meeting__aside-block {
  @apply bg-background rounded p-2 [&>*]:flex [&>*]:gap-1 first:[&>*>*]:flex-none last:[&>*>*]:space-y-1;
}
.meeting__aside-block + .meeting__aside-block {
  @apply mt-4;
}
.meeting__aside-block__title {
  @apply text-gray-2 text-sm font-semibold;
}
.meeting__aside-block__text {
  @apply text-gray-2 text-sm;
}
.meeting__aside-block__large {
  @apply text-gray-2 text-xl font-bold;
}
.meeting__aside-block__list {
  @apply ml-4 list-disc text-gray-2 text-sm;
}
.meeting__aside-actions {
  @apply flex flex-wrap justify-center gap-x-2;
}
.meeting__registration-modal__terms {
  @apply text-xs text-gray-2;
}
.meeting__cancelation-modal__description {
  @apply mt-8 mb-16 text-lg text-gray-2;
}

.meeting-poll__layout header,
.meeting-poll__layout main h1,
.meeting-poll__layout footer {
  @apply hidden md:block;
}
.meeting-poll__layout .layout-1col {
  padding: 0;
}

.meeting-polls {
  @apply m-0 md:mt-10 md:mb-24;
  counter-reset: question;
}
.meeting-polls__question {
  @apply bg-white;
}
.meeting-polls__question summary {
  @apply p-4 font-normal text-black text-md transition bg-background cursor-pointer marker:text-secondary;
  transition: background-color 0.2s ease-in-out;
}
.meeting-polls__question summary > span:first-child::after {
  counter-increment: question;
  content: "#" counter(question);
}
.meeting-polls__question summary > span:last-child:not(:only-child) {
  @apply text-sm font-semibold float-right;
}
.meeting-polls__question summary + * {
  @apply mt-4 mb-8 md:mb-16 pr-4 pl-[calc(1rem+14px)] md:px-0 space-y-6;
}
.meeting-polls__question[open] summary {
  @apply bg-secondary md:bg-background marker:text-white md:marker:text-secondary text-white md:text-black;
}
.meeting-polls__question.is-new {
  animation: animateHighlight 5s ease-in-out forwards;
}
.meeting-polls__question + .meeting-polls__question {
  @apply mt-4;
}
@keyframes animateHighlight {
  0%, 80% {
    background-color: rgba(var(--warning-rgb), 0.1);
  }
}
.meeting-polls__answer label {
  @apply block p-4 ring-4 ring-background rounded transition;
}
.meeting-polls__answer label:not(:has([disabled])) {
  @apply hover:ring-tertiary hover:cursor-pointer;
}
.meeting-polls__answer label + label, .meeting-polls__answer + .meeting-polls__answer {
  @apply mt-4;
}
.meeting-polls__answer--value {
  @apply flex gap-2 justify-between text-gray-2 text-lg;
}
.meeting-polls__answer--value > *:last-child {
  @apply w-1/6 flex-none text-gray text-right;
}
.meeting-polls__answer--bar {
  @apply w-full h-2.5 overflow-hidden rounded bg-background;
}
.meeting-polls__answer--bar > * {
  @apply bg-success h-full rounded;
}
.meeting-polls__admin-action {
  @apply py-4 grid grid-cols-2 gap-x-4 gap-y-8 border-t border-t-gray [&>*:nth-child(2)]:ml-auto [&>*:nth-child(3)]:col-span-2;
}
.meeting-polls__topbar {
  @apply my-4 md:my-0 px-4 md:px-0 py-2 md:py-10 flex justify-between gap-4 bg-background md:bg-transparent;
}
.meeting-polls__topbar.is-admin {
  @apply mt-0 bg-tertiary md:bg-transparent text-black;
}

.meeting-calendar {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-4 md:gap-y-6 md:gap-x-8 lg:gap-y-12 lg:gap-x-16 place-items-start;
}
.meeting-calendar__selector {
  @apply flex items-center gap-4;
}
.meeting-calendar__selector-container {
  @apply flex justify-between items-center mb-8;
}
.meeting-calendar__selector-arrow {
  @apply w-6 h-6 rounded-full bg-background;
}
.meeting-calendar__link {
  @apply flex items-center gap-2 text-secondary font-semibold;
}
.meeting-calendar__month {
  @apply text-center border-separate;
}
.meeting-calendar__month caption {
  @apply text-lg text-gray-2 text-left font-semibold mb-4;
}
.meeting-calendar__month th {
  @apply text-xs text-black uppercase font-semibold;
}
.meeting-calendar__month td {
  @apply text-md text-gray-2 font-semibold w-9 h-9 rounded;
}
.meeting-calendar__month .is-today {
  @apply border border-gray bg-background font-bold;
}
.meeting-calendar__month .is-past-event {
  @apply bg-gray-2 text-white font-bold;
}
.meeting-calendar__month .is-upcoming-event {
  @apply bg-secondary text-white font-bold;
}

.meeting-polls__body {
  @apply flex flex-col min-h-screen;
}
.meeting-polls__header {
  @apply flex items-center justify-between p-2.5 bg-background border;
}
.meeting-polls__main {
  @apply flex grow items-stretch;
}
.meeting-polls__iframe {
  @apply flex grow bg-gray-2 [&>*]:grow [&>*]:border-0;
}
.meeting-polls__aside {
  @apply flex-none bg-background-2;
}
.meeting-polls__aside.is-open {
  @apply w-1/5 [&+div]:w-4/5;
}
.meeting-polls__aside.is-open + .meeting-polls__aside.is-open + div {
  @apply w-3/5;
}
.meeting-polls__aside.is-open + .meeting-polls__aside.is-open {
  @apply border-l border-l-background;
}